// .daily-deals {
// 	.single-deal {
// 		border-right:4px solid $error;
// 		transition:all 350ms ease-in-out;
// 		margin-bottom:15px;
// 		&:last-of-type {
// 			margin-bottom:0;
// 		}
// 		.single-deal-button {
// 			opacity:0;
// 		}
// 		&:hover {
// 			cursor:pointer;
// 			box-shadow:0;
// 			background:$gray;
// 			color:white!important;
// 			.single-deal-button {
// 				opacity:1;
// 			}
// 			.deal-fine-print {
// 				color:white;
// 			}
// 		}
// 		p {
// 			margin-bottom:0;
// 		}
// 	}
// }

.daily-deals {
	h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
		margin-top:0 !important;
	}
	.card-columns {
		column-count: 1!important;
		@include media-breakpoint-up(lg) {
			column-count: 2!important;
		}
		.card {
			border:0!important;
		}
	}
	.single-deal {
		border-right:4px solid $error;
		transition:all 350ms ease-in-out;
		margin-bottom:15px;
		&:last-of-type {
			margin-bottom:0;
		}
		.single-deal-button {
			opacity:0;
		}
		&:hover {
			cursor:pointer;
			box-shadow:0;
			background:$brand-primary!important;
			color:white!important;
			.single-deal-button {
				opacity:1;
			}
			.deal-fine-print {
				color:white;
			}
		}
		p {
			margin-bottom:0;
		}
	}
}